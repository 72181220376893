import React, { Component } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import {
  InnerGrid,
  Raised,
  SubHead,
  TextGroupHeader,
} from '../components/LandingAssets';
import ImageHeaderSubHeader from '../components/hidden_pages/ImageHeaderSubheader';
import Grid from '../components/grid';
import axios from 'axios';
import { wrapComponent } from '../utils';
import queryString from 'query-string';
import {
  lightGreenBgColor,
  slateColor,
  slateLight30Color,
  reminder200,
  removePersonalInformationAppleWatchUrl,
  redTextColor,
  REPORT_LOST_WATCH_ROUTE,
} from '../constants';
import { linkExpired } from '../constants';
import Image from '../components/images/Image';
import { trackPageViewForCurrentPath } from '../tracking';

const SubmitButton = styled.button`
  margin: '1rem 0';
  background-color: ${() => slateColor};
  &:disabled {
    background-color: ${() => slateLight30Color};
  }
`;

class ShippingInstructions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: null,
      unknownError: false,
      knownError: false,
      hasSubmitted: false,
      lostWatchToken: null,
      backend: null,
    };
  }

  componentDidMount() {
    trackPageViewForCurrentPath();

    const queryStringValues = queryString.parse(this.props.location.search);
    const lostWatchToken = queryStringValues.lost_watch_token;
    const backend = queryStringValues.backend;

    if (!lostWatchToken) {
      this.setState({
        unknownError: true,
      });
    } else {
      this.setState({
        lostWatchToken,
        backend,
      });
    }
  }

  handleClick() {
    const queryStringValues = queryString.parse(this.props.location.search);
    const printLabelEmailToken = queryStringValues.token;

    if (!printLabelEmailToken) {
      this.setState({
        unknownError: true,
      });
      return;
    }

    this.setState(state => ({
      hasSubmitted: true,
    }));

    this.printShippingLabelWithBackend(printLabelEmailToken);
  }

  async printShippingLabelWithBackend(printLabelEmailToken) {
    try {
      const response = await axios.get(
        `/watch_retrieval/generate_shipping_label?token=${printLabelEmailToken}&no_redirect=true`,
      );

      if (response.status === 200) {
        this.setState({
          success: response.data.success,
        });

        window.open(response.data.link);
      }
    } catch (error) {
      const backendHandledStatuses = [400, 404];
      if (
        error.response &&
        backendHandledStatuses.includes(error.response.status)
      ) {
        this.setState({
          success: error.response.data.success,
          knownError: true,
        });
      } else {
        this.setState({
          unknownError: true,
        });
      }
      console.error(error);
    }
  }

  textAndImageToDisplay() {
    if (this.state.unknownError) {
      return {
        header: 'Oops! There was a problem.',
        subheader: 'Please try refreshing, or try again in a few minutes.',
      };
    } else if (this.state.knownError) {
      return {
        header: 'This link has expired',
        subheader:
          'The link to print UPS shipping label has expired. Please contact Heartline Support.',
        image: <Image src={linkExpired} alt="Link expired." dontUse2x />,
      };
    } else if (!this.state.unknownError) {
      return {
        header: 'It’s time to return your Apple Watch',
        image: (
          <Image
            src={reminder200}
            alt="Instructions for Printing Shipping Label."
            dontUse2x
          />
        ),
      };
    } else {
      return {
        header: '',
        subheader: '',
        image: '',
      };
    }
  }

  render() {
    const imageContainer = (
      <Grid.Box
        width={[1, 1, 1 / 2, 1 / 2]}
        mb={[rem(20), rem(20), 0, 0]}
        textAlign="center"
      >
        <Image
          src={reminder200}
          alt="Instructions for Printing Shipping Label"
          style={{ width: '100%' }}
          dontUse2x
        />
      </Grid.Box>
    );

    return (
      <Raised bg={lightGreenBgColor} role="main">
        <section role="main">
          <InnerGrid
            multiplier={4.5}
            px={rem(10)}
            pt={[rem(10), rem(10), rem(60), rem(60)]}
            pb={[rem(10), rem(10), rem(40), rem(40)]}
            mb={[rem(60), 0, 0, 0]}
          >
            <Grid.Flex
              flexWrap="wrap"
              justifyContent="center"
              alignItems="flex-start"
            >
              {!this.state.hasSubmitted &&
                !this.state.knownError &&
                !this.state.unknownError && (
                  <React.Fragment>
                    {imageContainer}
                    <TextGroupHeader
                      className="status-header"
                      style={{ textAlign: 'center' }}
                    >
                      It’s time to return your
                      <br />
                      Apple Watch
                    </TextGroupHeader>
                    <div className="subheader">
                      <SubHead>
                        Here’s how to return the Apple Watch you received on
                        loan from Heartline. If you report that your watch was
                        lost, you will not be eligible to receive the final
                        study compensation from Heartline.
                      </SubHead>
                      <SubHead>
                        <b>First, prepare your Apple Watch for return</b>
                      </SubHead>
                      <SubHead>
                        Before you return your watch, you need to remove your
                        personal information from it. Apple has prepared easy,
                        step-by-step instructions to show you how.
                      </SubHead>
                      <SubHead>
                        <a
                          href={removePersonalInformationAppleWatchUrl}
                          style={{ color: redTextColor }}
                        >
                          Click or tap to view instructions to remove personal
                          information from your Apple Watch &rsaquo;
                        </a>
                      </SubHead>
                      <SubHead>
                        <b>
                          Then, send it back to us using a prepaid UPS label
                        </b>
                      </SubHead>
                      <SubHead>
                        1. Request a free, prepaid UPS shipping label from us
                        through the button below.
                      </SubHead>
                      <SubHead>2. Print the label yourself.</SubHead>
                      <SubHead>
                        3. Package your Apple Watch in a small box or padded
                        envelope, and affix the label to the package.
                      </SubHead>
                      <SubHead>
                        4. Take your package to a local UPS Store, or schedule a
                        pick up by calling &nbsp;
                        <a href="tel:+18007425877">1-800-742-5877.</a>
                      </SubHead>
                      <SubHead>
                        Then, send it back to us using a prepaid UPS label
                      </SubHead>
                      <SubHead>
                        After you return your Apple Watch, we’ll send you a
                        quick survey via email. This survey will not be
                        available in the Heartline app.
                      </SubHead>
                      <SubmitButton onClick={() => this.handleClick()}>
                        Request prepaid UPS label
                      </SubmitButton>
                      <SubHead>
                        Has your Apple Watch been lost? &nbsp;
                        <a
                          href={
                            REPORT_LOST_WATCH_ROUTE +
                            '?backend=' +
                            this.state.backend +
                            '&token=' +
                            this.state.lostWatchToken
                          }
                          style={{ color: redTextColor }}
                        >
                          Report your lost watch &rsaquo;
                        </a>
                      </SubHead>
                      <SubHead>
                        Questions? Contact Heartline Support at
                        <a href="tel:+18779544021"> (877) 594-4021 </a>
                        or by email at
                        <a href="mailto:help@heartline.com">
                          {' '}
                          help@heartline.com.
                        </a>
                      </SubHead>
                    </div>
                  </React.Fragment>
                )}
              {(this.state.hasSubmitted || this.state.unknownError) && (
                <ImageHeaderSubHeader
                  hasError={this.state.unknownError}
                  contentToDisplay={this.textAndImageToDisplay()}
                />
              )}
            </Grid.Flex>
          </InnerGrid>
        </section>
      </Raised>
    );
  }
}

export default wrapComponent(ShippingInstructions);
